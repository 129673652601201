
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as $ from 'jquery';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import './css/style.css';
import '../src/css/mediaquery.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'react-responsive-modal/styles.css';

import reducer from './store/reducers';
import ReactGA from 'react-ga';

function saveToLocalStorage(state) {
  try {
    const serializedState = JSON.stringify(state)
    localStorage.setItem('state', serializedState)
  } catch (e) {
    console.log(e);
  }
}

function loadStoreFromLocalStorage() {
  try {
    const serializedState = localStorage.getItem('state')
    if (serializedState === null) return undefined
    return JSON.parse(serializedState)
  } catch (e) {
    console.log(e)
    return undefined
  }
}
ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);


ReactGA.pageview(window.location.pathname + window.location.search);
const persistedState = loadStoreFromLocalStorage()

const composeEnhancers = window._REDUX_DEVTOOLS_EXTENSION_COMPOSE_ || compose;
const store = createStore(reducer, persistedState, /* preloadedState, */ composeEnhancers(
  applyMiddleware(thunk)
));
store.subscribe(() => saveToLocalStorage(store.getState()))

ReactDOM.render(<Provider store={store}><App /></Provider>,
  document.getElementById('root')

);
window.jQuery = window.$ = $
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.register();
serviceWorker.unregister();
