import React, { Suspense, lazy } from "react";
import { Redirect, Switch } from "react-router-dom";
import Route from "./routes";

const LandingCopy = lazy(() =>
  import("../pages/LandingCopy" /* webpackChunkName: "LandingCopy" */)
);
const Signup = lazy(() =>
  import("../pages/Signup" /* webpackChunkName: "Signup" */)
);
const Login = lazy(() =>
  import("../pages/Login" /* webpackChunkName: "Login" */)
);
const Home = lazy(() => import("../pages/Home" /* webpackChunkName: "Home" */));
const Search = lazy(() =>
  import("../pages/Search" /* webpackChunkName: "Search" */)
);
const Signup2 = lazy(() =>
  import("../pages/Signup2" /* webpackChunkName: "Signup2" */)
);
const Course = lazy(() =>
  import("../pages/Course" /* webpackChunkName: "Course" */)
);
const CourseDetail = lazy(() =>
  import("../pages/CourseDetail" /* webpackChunkName: "CourseDetail" */)
);
const ProfileVideoLib = lazy(() =>
  import("../pages/ProfileVideoLib" /* webpackChunkName: "ProfileVideoLib" */)
);
const Profile = lazy(() =>
  import("../pages/Profile" /* webpackChunkName: "Profile" */)
);
const Payment = lazy(() =>
  import("../pages/Payment" /* webpackChunkName: "Payment" */)
);
const Howitworks = lazy(() =>
  import("../pages/Howitworks" /* webpackChunkName: "Howitworks" */)
);
const Wanttoteach = lazy(() =>
  import("../pages/Wanttoteach" /* webpackChunkName: "Wanttoteach" */)
);

const Enquiry = lazy(() =>
  import("../pages/Enquiry" /* webpackChunkName: "Enquiry" */)
);
const EnquiryNew = lazy(() =>
  import("../pages/EnquiryNew" /* webpackChunkName: "NewEnquiry" */)
);
const Payment1 = lazy(() =>
  import("../pages/Payment1" /* webpackChunkName: "Payment" */)
);
const TermAndConditions = lazy(() =>
  import(
    "../pages/TermAndConditions" /* webpackChunkName: "TermAndConditions" */
  )
);
// const ResetPassword = lazy(() => import('../pages/ResetPassword' /* webpackChunkName: "ResetPassword" */));
const Blog = lazy(() => import("../pages/blog" /* webpackChunkName: "Blog" */));
const WebinarSeries = lazy(() =>
  import("../pages/WebinarSeries" /* webpackChunkName: "WebinarSeries" */)
);
const MediaPage = lazy(() =>
  import("../pages/mediapage" /* webpackChunkName: "MediaPage" */)
);
const Faqs = lazy(() => import("../pages/Faqs" /* webpackChunkName: "Faqs" */));
const MusicWellness = lazy(() =>
  import("../pages/MusicWellness" /* webpackChunkName: "MusicWellness" */)
);
const ProfileEdit = lazy(() =>
  import("../pages/ProfileEdit" /* webpackChunkName: "ProfileEdit" */)
);
const BlogDetail = lazy(() =>
  import("../pages/blogdetail" /* webpackChunkName: "BlogDetail" */)
);
const MediaPageDetail = lazy(() =>
  import("../pages/mediaPageDetail" /* webpackChunkName: "MediaPageDetail" */)
);
const MusicWellnessDetails = lazy(() =>
  import(
    "../pages/MusicWellnessDetails" /* webpackChunkName: "MusicWellnessDetails" */
  )
);
const WebinarSeriesDetails = lazy(() =>
  import(
    "../pages/webinarSeriesDetails" /* webpackChunkName: "WebinarSeriesDetails" */
  )
);
const PrivacyPolicy = lazy(() =>
  import("../pages/PrivacyPolicy" /* webpackChunkName: "PrivacyPolicy" */)
);
const Book = lazy(() => import("../pages/Book" /* webpackChunkName: "Book" */));
const PageNotFound = lazy(() =>
  import("../pages/PageNotFound" /* webpackChunkName: "PageNotFound" */)
);
const Geolocation = lazy(() =>
  import("../pages/Geolocation" /* webpackChunkName: "Geolocation" */)
);
const EnquiryNewUs = lazy(() =>
  import("../pages/EnquiryNewUs" /* webpackChunkName: "EnquiryNewUs" */)
);
const ClassSummary = lazy(() =>
  import("../pages/ClassSummary" /* webpackChunkName: "ClassSummary" */)
);
const ShorterEnquiry = lazy(() =>
  import("../pages/ShorterEnquiry" /* webpackChunkName: "ShorterEnquiry" */)
);
const ShorterEnquiryIn = lazy(() =>
  import("../pages/ShorterEnquiryIn" /* webpackChunkName: "ShorterEnquiryIn" */)
);
const Curriculum = lazy(() =>
  import("../pages/Curricullum" /* webpackChunkName: "ShorterEnquiryIn" */)
);
const GradeListing = lazy(() => import('../pages/GradeListing' /* webpackChunkName: "ShorterEnquiryIn" */));
const QuizSection = lazy(() => import('../pages/QuizSection' /* webpackChunkName: "ShorterEnquiryIn" */));
const CurriculumPractice = lazy(() =>
  import(
    "../pages/CurriculumPractice" /* webpackChunkName: "CurriculumPractice" */
  )
);
const StudentDashboard = lazy(() =>
  import("../pages/StudentDashboard" /* webpackChunkName: "StudentDashboard" */)
);
const StudentAttendance = lazy(() =>
  import(
    "../pages/StudentAttendanceHistory" /* webpackChunkName: "StudentAttendance" */
  )
);
const TimeSelection = lazy(() =>
  import("../pages/TimeSelection" /* webpackChunkName: "TimeSelection" */)
);
const TimeSelectionshowcase = lazy(() =>
import("../pages/TimeSelectionshowcase" /* webpackChunkName: "TimeSelectionshowcase" */)
);
const RescheduleClass = lazy(() =>
  import("../pages/RescheduleClass" /* webpackChunkName: "RescheduleClass" */)
);
const ConfirmPassword = lazy(() =>
  import("../pages/ConfirmPassword" /* webpackChunkName: "ConfirmPassword" */)
);
const ReferralProgram = lazy(() =>
  import("../pages/ReferralProgram" /* webpackChunkName: "ReferralProgram" */)
);
const StudentExamList = lazy(() =>
  import(
    "../components/StudentDashboardComponent/Exam/StudentExamList" /* webpackChunkName: "StudentExamList" */
  )
);
const StartExam = lazy(() =>
  import(
    "../components/StudentDashboardComponent/Exam/StartExam" /* webpackChunkName: "StudentExamList" */
  )
);
const PaymentOrder = lazy(() =>
  import("../pages/PaymentOrder" /* webpackChunkName: "PaymentOrder" */)
);
const PaymentorderProcess = lazy(() => import('../pages/PaymentorderProcess' /* webpackChunkName: "PaymentorderProcess" */));
const VideoExamList = lazy(() => import('../components/StudentDashboardComponent/Exam/VideoExamList' /* webpackChunkName: "PaymentOrder" */));




export default function Routes() {
  return (
    <Suspense fallback={<div />}>
      <Switch>
        <Route path="/" exact component={(props) => <Login {...props} />} />
        <Route
          path="/Landing"
          component={(props) => <LandingCopy {...props} />}
        />
        <Route
          path="/login"
          exact
          component={(props) => <Login {...props} />}
        />
        <Route
          path="/Signup"
          exact
          component={(props) => <Signup {...props} />}
        />
        <Route
          path="/Signup2"
          exact
          component={(props) => <Signup2 {...props} />}
        />
        <Route
          path="/howitworks"
          exact
          component={(props) => <Howitworks {...props} />}
        />
        <Route
          path="/wanttoteach"
          exact
          component={(props) => <Wanttoteach {...props} />}
        />
        <Route
          path="/Search/:keyword"
          exact
          component={(props) => <Search {...props} />}
        />
        <Route
          path="/profile/videolib"
          exact
          component={(props) => <ProfileVideoLib {...props} />}
        />
        <Route
          path="/old-profile"
          exact
          component={(props) => <Profile {...props} />}
        />
        <Route
          path="/profile"
          exact
          component={(props) => <StudentDashboard {...props} />}
        />
        <Route
          path="/student-attendance"
          exact
          component={(props) => <StudentAttendance {...props} />}
        />
        <Route
          exact
          path="/profile/edit"
          component={(props) => <ProfileEdit {...props} />}
        />
        {/*<Route path="/setpassword" exact component={props => <SetPassword {...props} />}/> */}
        <Route
          path="/reschedule-class"
          exact
          component={(props) => <RescheduleClass {...props} />}
        />
        {/* <Route path="/reset-password" exact component={props => <ResetPassword {...props} />} /> */}
        <Route path="/home" exact component={(props) => <Home {...props} />} />
        <Route
          path="/courses/:categoryName"
          exact
          component={(props) => <Course {...props} />}
        />
        <Route
          path="/course/:productName"
          exact
          component={(props) => <CourseDetail {...props} />}
        />
        <Route
          path="/payment/:batchId"
          exact
          component={(props) => <Payment {...props} />}
        />
        <Route
          path="/payment/"
          exact
          component={(props) => <Payment1 {...props} />}
        />
        <Route
          path="/enquiry"
          exact
          component={(props) => <Enquiry {...props} />}
        />
        <Route
          path="/enquiry/:id"
          exact
          component={(props) => <Enquiry {...props} />}
        />
        <Route
          path="/new-enquiry"
          exact
          component={(props) => <EnquiryNew {...props} />}
        />
        <Route
          path="/shorter-enquiry"
          exact
          component={(props) => <ShorterEnquiry {...props} />}
        />
        <Route
          path="/shorter-enquiry-in"
          exact
          component={(props) => <ShorterEnquiryIn {...props} />}
        />
        <Route
          path="/shorter-enquiry-in/:id"
          exact
          component={(props) => <ShorterEnquiryIn {...props} />}
        />
        <Route
          path="/new-enquiry/:id"
          exact
          component={(props) => <EnquiryNew {...props} />}
        />
        <Route
          path="/enquiry-us"
          exact
          component={(props) => <EnquiryNewUs {...props} />}
        />
        <Route
          path="/enquiry-us/:id"
          exact
          component={(props) => <EnquiryNewUs {...props} />}
        />
        <Route
          path="/termAndConditions"
          exact
          component={(props) => <TermAndConditions {...props} />}
        />
        <Route path="/blog" exact component={(props) => <Blog {...props} />} />
        <Route
          path="/blog/:blogDetail"
          exact
          component={(props) => <BlogDetail {...props} />}
        />
        <Route
          path="/webinarseries"
          exact
          component={(props) => <WebinarSeries {...props} />}
        />
        <Route
          path="/webinarseries/:webinarSeriesDetails"
          exact
          component={(props) => <WebinarSeriesDetails {...props} />}
        />
        <Route
          path="/mediapage"
          exact
          component={(props) => <MediaPage {...props} />}
        />
        <Route
          path="/mediapage/:MediaDetail"
          exact
          component={(props) => <MediaPageDetail {...props} />}
        />
        <Route
          path="/music-and-wellness"
          exact
          component={(props) => <MusicWellness {...props} />}
        />
        <Route
          path="/music-and-wellness/:MusicWellnessDetail"
          exact
          component={(props) => <MusicWellnessDetails {...props} />}
        />
        <Route path="/faqs" exact component={(props) => <Faqs {...props} />} />
        <Route
          path="/courselist/:geocategory"
          exact
          component={(props) => <Geolocation {...props} />}
        />
        {/*<Route path="/blogdetail" exact component={props => <BlogDetail {...props} />}/>*/}
        <Route
          path="/curriculumpractice"
          exact
          component={(props) => <CurriculumPractice {...props} />}
        />
        <Route
          path="/curriculum"
          exact
          component={(props) => <Curriculum {...props} />}
        />
          <Route path="/grade" exact component={props => <GradeListing {...props} />} />
          <Route path="/quiz" exact component={props => <QuizSection {...props} />} />
        <Route
          path="/class-summary"
          exact
          component={(props) => <ClassSummary {...props} />}
        />
        <Route
          path="/PrivacyPolicy"
          exact
          component={(props) => <PrivacyPolicy {...props} />}
        />
        <Route path="/book" exact component={(props) => <Book {...props} />} />
        <Route
          path="/timeselection"
          exact
          component={(props) => <TimeSelection {...props} />}
        />
       <Route path="/timeselection-showcase" exact component={props => <TimeSelectionshowcase {...props} />} />
        <Route
          path="/reset-password"
          component={(props) => <ConfirmPassword {...props} />}
        />
        <Route
          path="/referral-program"
          component={(props) => <ReferralProgram {...props} />}
        />
        <Route
          path="/page-not-found"
          component={(props) => <PageNotFound {...props} />}
        />
        <Route
          path="/exam-list"
          component={(props) => <StudentExamList {...props} />}
        />
        <Route
          path="/start-exam"
          component={(props) => <StartExam {...props} />}
        />
        <Route
          path="/payment-order"
          exact
          component={(props) => <PaymentOrder {...props} />}
        />
         <Route
         path="/video-exam-list"
          exact
          component={props => <VideoExamList {...props} />}
        />
        <Route path="/payment-order-process" exact component={props => <PaymentorderProcess {...props} />} />

        <Redirect
          from="*"
          to={{ pathname: "/page-not-found", state: { status: 404 } }}
        />


      </Switch>
    </Suspense>
  );
}
