const LOGIN = "LOGIN";
const LOGOUT = "LOGOUT";
const LANDINGPAGE = "LANDINGPAGE";
const LANDINGPAGECOPY = "LANDINGPAGECOPY";
const CATEGORY = "CATEGORY";
const COURSE = "COURSE";
const SIGNUP = "SIGNUP";
const PROFILE = "PROFILE";
const ENQUIRY = "ENQUIRY";
const BLOG = "BLOG";
const WEBINAR = "WEBINAR";
const MEDIA = "MEDIA";
const MUSICWELLNESS = "MUSICWELLNESS";
const BOUNCEUSERPOPUP = "BOUNCEUSERPOPUP";
const SESSIONDATA = "SESSIONDATA";
const COURSEDETAILSBATCHES = "COURSEDETAILSBATCHES";
const COURSEDETAILSIMILIARCOURSE = "COURSEDETAILSIMILIARCOURSE";
const COURSEDETAILSREIVEW = "COURSEDETAILSREIVEW";
const LANDINGREIVEW = "LANDINGREIVEW";
const REFBOOK = "REFBOOK";
const STUDENTENROLLCOURSES = "STUDENTENROLLCOURSES";
const STUDENTSCHEDULECOURSES = "STUDENTSCHEDULECOURSES"
const  USERDATAFORGOT="USERDATAFORGOT"





export {USERDATAFORGOT, LOGIN, LANDINGPAGE, STUDENTENROLLCOURSES, STUDENTSCHEDULECOURSES, LANDINGPAGECOPY, LOGOUT, CATEGORY, COURSE, SIGNUP, REFBOOK, PROFILE, ENQUIRY, BLOG, WEBINAR, MEDIA, MUSICWELLNESS, BOUNCEUSERPOPUP, SESSIONDATA, COURSEDETAILSBATCHES, COURSEDETAILSIMILIARCOURSE, COURSEDETAILSREIVEW, LANDINGREIVEW };
