import { USERDATAFORGOT, LOGIN, LANDINGPAGE, STUDENTSCHEDULECOURSES, STUDENTENROLLCOURSES, LANDINGPAGECOPY, COURSEDETAILSBATCHES, COURSEDETAILSIMILIARCOURSE, COURSEDETAILSREIVEW, COURSE, LOGOUT, SIGNUP, PROFILE, ENQUIRY, BLOG, WEBINAR, MEDIA, MUSICWELLNESS, BOUNCEUSERPOPUP, SESSIONDATA, CATEGORY, LANDINGREIVEW, REFBOOK } from './types';

const initialState = {
    userData: undefined,
    access_token: undefined,
    pageData: undefined,
    categoryData: undefined,
    courseData: undefined,
    learnerData: undefined,
    enquiryCatData: undefined,
    blogData: undefined,
    webinarData: undefined,
    mediaData: undefined,
    musicWellnessData: undefined,
    blogDetailData: undefined,
    categorywiseData: undefined,
    BounceUserPopupData: false,
    sessionPriceData: undefined,
    courseDetailsBatchesData: undefined,
    courseDetailsSimilarCoursesData: undefined,
    courseDetailsReviewData: undefined,
    landingReviewData: undefined,
    refBookData: undefined,
    studentEnrollCoursesData: undefined,
    studentScheduleCoursesData: undefined,
    userDataForgotData: undefined










}

const reducer = (state = initialState, action) => {
    const modifiedState = { ...state }
    switch (action.type) {
        case LOGIN:

            return {
                ...modifiedState,
                access_token: action.payload.access_token
            }

        case LANDINGPAGE:
            return {
                ...modifiedState,
                pageData: action.payload.pageData
            }
        case LANDINGPAGECOPY:
            return {
                ...modifiedState,
                categorywiseData: action.payload.categorywiseData
            }
        case CATEGORY:
            return {
                ...modifiedState,
                categoryData: action.payload.categoryData
            }

        case COURSE:
            return {
                ...modifiedState,
                courseData: action.payload.courseData
            }

        case SIGNUP:
            return {
                ...modifiedState,
                userData: action.payload.userData
            }

        case ENQUIRY:
            return {
                ...modifiedState,
                enquiryCatData: action.payload.enquiryCatData
            }

        case PROFILE:
            return {
                ...modifiedState,
                learnerData: action.payload.learnerData
            }
        case BLOG:
            return {
                ...modifiedState,
                blogData: action.payload.blogData
            }
        case WEBINAR:
            return {
                ...modifiedState,
                webinarData: action.payload.webinarData
            }
        case MEDIA:
            return {
                ...modifiedState,
                mediaData: action.payload.mediaData
            }
        case MUSICWELLNESS:
            return {
                ...modifiedState,
                musicWellnessData: action.payload.musicWellnessData
            }

        case BOUNCEUSERPOPUP:
            return {
                ...modifiedState,
                BounceUserPopupData: action.payload.BounceUserPopupData
            }
        case SESSIONDATA:
            return {
                ...modifiedState,
                sessionPriceData: action.payload.sessionPriceData
            }

        case COURSEDETAILSBATCHES
            :
            return {
                ...modifiedState,
                courseDetailsBatchesData: action.payload.courseDetailsBatchesData
            }
        case COURSEDETAILSIMILIARCOURSE
            :
            return {
                ...modifiedState,
                courseDetailsSimilarCoursesData: action.payload.courseDetailsSimilarCoursesData
            }

        case COURSEDETAILSREIVEW
            :
            return {
                ...modifiedState,
                courseDetailsReviewData: action.payload.courseDetailsReviewData
            }


        case LANDINGREIVEW
            :
            return {
                ...modifiedState,
                landingReviewData: action.payload.landingReviewData
            }

        case USERDATAFORGOT:

            return {
                ...modifiedState,
                userDataForgotData: action.payload.userDataForgotData
            }


        case REFBOOK:
            return {
                ...modifiedState,
                refBookData: action.payload.refBookData
            }

        case STUDENTENROLLCOURSES:
            return {
                ...modifiedState,
                studentEnrollCoursesData: action.payload.studentEnrollCoursesData
            }

        case STUDENTSCHEDULECOURSES:
            return {
                ...modifiedState,
                studentScheduleCoursesData: action.payload.studentScheduleCoursesData
            }


        case LOGOUT:
            return {
                access_token: undefined,
                learnerData: undefined
            }
        default:
    }

    return modifiedState;
}

export default reducer;